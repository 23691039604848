import * as React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../components/layout';
import { SEO } from '../components/seo';
import { graphql } from 'gatsby';

function Page({ pageContext }) {
  const page = pageContext;
  return (
    <Layout>
      <div className="page-banner has-text-centered py-6">
        <h1 className="title m-2">{page.frontmatter.title}</h1>
      </div>
      <div className="content container p-5">
        <MDXRenderer>{page.body}</MDXRenderer>
      </div>
    </Layout>
  );
}

export default Page;

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
      }
      body
    }
  }
`;

export const Head = ({
  data: {
    mdx: {
      frontmatter: { title },
    },
  },
}) => {
  return <SEO title={title} />;
};
